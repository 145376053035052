import { PanelPlugin } from '@grafana/data';
import { OptionsArray, StreamingPanelOptions } from './types';
import { StreamingPanel } from './StreamingPanel';

export const plugin = new PanelPlugin<StreamingPanelOptions>(StreamingPanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      path: 'streamingServerURL',
      name: 'Streaming server URL',
      description: 'Signaler server (WebSocket)',
      defaultValue: 'i4sbased-streaming-server.azurewebsites.net/ws',
    }).addTextInput({
      path: 'sensorID',
      name: 'SensorID',
      description: 'Sensor ID (ex. M1). Can be variable.',
      defaultValue: '',
    }).addTextInput({
      path: 'stunAddress',
      name: 'Stun server address',
      description: 'Stun server address and port',
      defaultValue: '52.157.207.114:3478',
    }).addTextInput({
      path: 'turnAddress',
      name: 'Turn server address',
      description: 'Turn server address and port',
      defaultValue: '52.157.207.114:3478',
    }).addTextInput({
      path: 'turnUsername',
      name: 'Turn server username',
      description: 'Username for turn server',
      defaultValue: '',
    }).addTextInput({
      path: 'turnCredential',
      name: 'Turn server credential',
      description: 'Credential for turn server',
      defaultValue: '',
    }).addTextInput({
      path: 'subscriptionKey',
      name: 'Subscription key',
      description: 'Azure Api Management subscription key',
      defaultValue: '',
    }).addTextInput({
      path: 'qrcodeurl',
      name: 'QR Code Url',
      description: 'QR Code Url (without base domain)',
      defaultValue: '/publicapi/api/Streaming/GenerateQRCode/',
    }).addSelect({
      name: "Video resolution",
      path: "resolution",
      category: ["Video resolution"],
      defaultValue: 1,
      settings: {
        options: OptionsArray
      }
    }).addTextInput({
      path: 'customWidth',
      name: 'Custom width',
      category: ["Video resolution"],
      defaultValue: '1080',
      showIf: (options) => {
        return (options.resolution == -1)
      }
    }).addTextInput({
      path: 'customHeight',
      name: 'Custom height',
      category: ["Video resolution"],
      defaultValue: '1920',
      showIf: (options) => {
        return (options.resolution == -1)
      }
    })
});
