import { DateTime } from "@grafana/data";

export interface StreamingPanelOptions {
  streamingServerURL: string;
  sensorID: string;
  stunAddress: string;
  turnAddress: string;
  turnUsername: string;
  turnCredential: string;
  subscriptionKey: string;
  qrcodeurl: string;
  resolution: any;
  customWidth: number;
  customHeight: number;
}

export type StreamHost = {
  number: string;
  name: string;
  sensorID: string;
  offer: string;
  startDate: DateTime;
  inUse: boolean;
};

export const OptionsArray = [
  { label: "240x480", value: 0, w: 240, h: 480 },
  { label: "360x640", value: 1, w: 360, h: 640 },
  { label: "540x960", value: 2, w: 540, h: 960 },
  { label: "720x1280", value: 3, w: 720, h: 1280 },
  { label: "1080x1920", value: 4, w: 1080, h: 1920 },
  { label: "Custom", value: -1, w: 1080, h: 1920 }]