import React from 'react';
import { PanelProps } from '@grafana/data';
import { OptionsArray, StreamHost, StreamingPanelOptions } from 'types';
import { Button, LoadingPlaceholder, Modal } from '@grafana/ui';
import { getDataSourceSrv, loadPluginCss } from '@grafana/runtime';
import axios from 'axios';

interface Props extends PanelProps<StreamingPanelOptions> { }

export const StreamingPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  loadPluginCss({
    dark: 'plugins/streaming-panel/css/base.css',
    light: 'plugins/streaming-panel/css/base.css',
  });

  const [isLive, setIsLive] = React.useState(false);

  const [modalIsOpen, setModalIsOpen,] = React.useState(false);
  const [modalListIsOpen, setModalListIsOpen,] = React.useState(false);
  const [modalQrIsOpen, setModalQrIsOpen,] = React.useState(false);

  const [videoWidth, setVideoWidth] = React.useState(0);
  const [videoHeight, setVideoHeight] = React.useState(0);

  const [sensorId, setSensorId] = React.useState(replaceVariables(options.sensorID));

  const [hasActiveStream, setHasActiveStream] = React.useState(false);
  const [hasOtherStreams, setHasOtherStreams] = React.useState(false);
  const [basse64QR, setBasse64QR] = React.useState("");

  const [isMuted, setIsMuted] = React.useState(false);

  var pc: RTCPeerConnection;
  var ws: WebSocket;

  const [stream, setStream] = React.useState({} as MediaStream);
  const localDataChannel = React.useRef({} as RTCDataChannel);

  var hosts: StreamHost[] = [];
  var sensorHost: StreamHost;

  const [selectedHost, setSelectedHost] = React.useState({} as StreamHost);
  var proxyURL: string;

  React.useEffect(() => {
    if (modalIsOpen) {
      console.log("SensorID: " + sensorId);
      connect();
    }
    else {
      const event = new Event('close');
      document.dispatchEvent(event);
    }

  }, [modalIsOpen]);

  const connect = async function () {
    const closePeer = function () {
      console.log("close peer and websocket");
      if (pc != null)
        pc.close();

      if (ws != null)
        ws.close();

      setIsLive(false);
      setMuted(true);
    };

    closePeer();

    const remoteVideo: HTMLVideoElement = document.getElementById('remoteVideo') as HTMLVideoElement;

    remoteVideo.addEventListener('loadedmetadata', function () {
      console.log(`Remote video videoWidth: ${this.videoWidth}px,  videoHeight: ${this?.videoHeight}px`);
      setVideoResolution();
    });

    remoteVideo.addEventListener('resize', () => {
      console.log(`Remote video size changed to ${remoteVideo?.videoWidth}x${remoteVideo?.videoHeight}`);
      setVideoResolution();
    });

    (document.getElementById('remoteVideoContainer') as HTMLElement).addEventListener('click', (e) => {
      addFocusPoint(e);
      var offset = (document.getElementById('remoteVideo') as HTMLElement).getClientRects()[0];
      const msg = JSON.stringify({
        "x": (e.clientX - offset.left) / (document.getElementById('remoteVideo') as HTMLVideoElement).width,
        "y": (e.clientY - offset.top) / (document.getElementById('remoteVideo') as HTMLVideoElement).height
      });

      sendMessage(msg, localDataChannel.current);
      e.preventDefault();
    });

    console.log("Connecting ...")

    function gotRemoteStream(e: RTCTrackEvent) {
      if (e.streams.length > 0) {
        if (remoteVideo.srcObject !== e.streams[0]) {
          remoteVideo.srcObject = e.streams[0];
          console.log('received remote stream');
        }
      } else {
        console.log(`No valid stream received.`);
      }
    }

    ws = new WebSocket(`wss:\\${options.streamingServerURL}`, []);

    ws.onmessage = async function (evt) {
      let obj = JSON.parse(evt.data);
      console.log(evt.data);
      if (obj['type'] == 'ice') {
        console.log("remote ice candidate: " + obj['candidate']);
        pc.addIceCandidate({ candidate: obj['candidate'], sdpMid: obj['sdpMid'], sdpMLineIndex: obj['sdpMLineIndex'] });
      }
      else if (obj.offer) {
        console.log("received offer ..." + selectedHost.number)
      }
      else if (obj.answer) {
        console.log("answer sdp: " + obj.answer);
        await pc.setRemoteDescription(new RTCSessionDescription({ type: "answer", sdp: obj.answer }))
      } else {
        console.log("unknown message: ", obj)
      }
    };

    ws.onopen = async function () {
      console.log("Connection open");
      let obj = JSON.parse(selectedHost.offer);
      console.log("received offer ..." + selectedHost.number)
      console.log(obj);

      let configuration = { iceServers: [] as any[] };
      if (options.turnAddress && options.turnUsername && options.turnCredential) {
        configuration.iceServers.push({
          url: 'turn:' + options.turnAddress,
          username: options.turnUsername,
          credential: options.turnCredential
        });
      }

      if (options.stunAddress) {
        configuration.iceServers.push({
          url: 'stun:' + options.stunAddress
        })
      }

      pc = new RTCPeerConnection(configuration);

      pc.addEventListener("icegatheringstatechange", (ev: Event) => {
        console.log('ice gathering state change state: ' + (ev.target as RTCPeerConnection).iceGatheringState);
        console.log(ev);
      }, false);

      pc.addEventListener("icecandidate", (ev) => {
        if (ev.candidate != null) {
          console.log(ev.candidate);
          ws.send(JSON.stringify({
            "subscriptionKey": options.subscriptionKey,
            "ice": {
              'type': 'ice',
              'candidate': ev.candidate.candidate,
              'sdpMLineindex': ev.candidate.sdpMLineIndex,
              'sdpMid': ev.candidate.sdpMid
            },
            target: selectedHost.number
          }));
        }
      }, false);

      pc.addEventListener("iceconnectionstatechange", (ev: Event) => {
        const status = (ev.currentTarget as RTCPeerConnection).iceConnectionState;
        console.log('ice connection state change state: ' + status);
        setIsLive((status !== "disconnected"));
        if (status === 'connected') {
          ws.close();
        }
      }, false);

      pc.addEventListener('track', gotRemoteStream);
      pc.ondatachannel = (event) => {
        console.log('ondatachannel');
        localDataChannel.current = event.channel;
      }

      //create local data channel
      // var _channel = pc.createDataChannel('messaging-channel', { ordered: true });
      // _channel.binaryType = 'arraybuffer';
      // localDataChannel.current = _channel;

      await openCall(pc);

      await pc.setRemoteDescription(new RTCSessionDescription({ type: "offer", sdp: obj['offer'] }))
      const answer = await pc.createAnswer()

      await pc.setLocalDescription(new RTCSessionDescription(answer));
      ws.send(JSON.stringify({
        "subscriptionKey": options.subscriptionKey,
        "answer": { type: "sdp", answer: answer.sdp },
        "target": selectedHost.number
      }));
      console.log("answer send");
    };

    document.addEventListener('close', function () {
      closePeer();
    });
  }

  const sendMessage = async function (value: string, channel: RTCDataChannel) {
    if (value === '') {
      console.log('Not sending empty message!');
      return;
    }
    console.log('Sending remote message: ', value);
    if (channel.readyState == "open")
      channel.send(value);
    else
      console.log('Cannel is not opened yet!');
  }

  const openCall = async (pc: RTCPeerConnection) => {
    var _stream: MediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    setStream(_stream);
    const audioTracks = _stream.getAudioTracks();
    if (audioTracks.length > 0) {
      console.log(`Using audio device: ${audioTracks[0].label}`);
      audioTracks[0].enabled = false;
      pc.addTrack(audioTracks[0], _stream);
    } else {
      console.log(`No audio tracks!`);
    }
  }

  const openModal = function (): void {
    setModalIsOpen(true);
  }
  const openModalList = function (): void {
    setModalListIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setModalListIsOpen(false);
    setModalQrIsOpen(false);
  };

  const setVideoResolution = () => {
    let vidWidth: number = 0;
    let vidHight: number = 0;
    if (options.resolution == -1) {
      vidWidth = options.customWidth;
      vidHight = options.customHeight;
    } else {
      vidWidth = OptionsArray[options.resolution].w;
      vidHight = OptionsArray[options.resolution].h;
    }
    if (videoWidth != vidWidth)
      setVideoWidth(vidWidth);
    if (videoHeight != vidHight)
      setVideoHeight(vidHight);
  }

  const renderPlayer = () => {
    return (
      <div hidden={!isLive}>
        <div className="streaming-audio-btn" onClick={e => toggleMuted(stream)}>
          <i className="fa fa-microphone-slash" aria-hidden="true" hidden={!isMuted}></i>
          <i className="fa fa-microphone" aria-hidden="true" hidden={isMuted}></i>
        </div>
        <div id="remoteVideoContainer">
          <video
            autoPlay={true}
            className="sp-video"
            width={videoWidth}
            height={videoHeight}
            id="remoteVideo"
          ></video>
        </div>
        {!isLive && (
          <div className="sp-offline">
            OFFLINE
          </div>
        )}
      </div>
    );
  }

  const loadQrCode = () => {
    if (hasQR())
      return;
    getProxyUrl().then(r => {
      const URL = window.location.origin + proxyURL + replaceVariables(options.qrcodeurl);
      axios.get(URL).then(
        r => {
          console.log(r);
          setBasse64QR("data:image/png;base64, " + r.data);
        },
        e => {
          console.log(e);
        }
      );
    });
  }
  const showQrCode = () => {
    setModalQrIsOpen(true);
  };

  const hasQR = (): boolean | undefined => {
    if (basse64QR && basse64QR !== "")
      return true;
    else
      return false;
  }

  const getStreams = () => {
    let hasStream = false;
    const sensor = replaceVariables(options.sensorID);
    if (sensorId !== sensor)
      setSensorId(sensor);

    const streams: StreamHost[] = [];
    if (data !== undefined && data.series !== undefined) {
      const table = data.series.find(s => s.refId === 'A') ?? data.series.find(s => s.refId === 'Streams');
      if (table !== undefined && table.fields !== undefined && table.fields.length > 0 && table.fields[0]?.values !== undefined) {
        const colNumber = (table.fields.find(f => f.name === 'Number') as any)?.values.toArray() ?? [];
        const colName = (table.fields.find(f => f.name === 'Name') as any)?.values.toArray() ?? [];
        const colSensorID = (table.fields.find(f => f.name === 'SensorID') as any)?.values.toArray() ?? [];
        const colOffer = (table.fields.find(f => f.name === 'SdpMessage') as any)?.values.toArray() ?? [];
        const colStartDate = (table.fields.find(f => f.name === 'CreatedDate') as any)?.values.toArray() ?? [];
        const colInUse = (table.fields.find(f => f.name === 'InUse') as any)?.values.toArray() ?? [];

        table.fields[0].values.toArray().forEach((r: string, i) => {
          const v: StreamHost = {
            sensorID: colSensorID[i] === null ? '' : colSensorID[i],
            number: colNumber[i],
            name: colName[i],
            offer: colOffer[i],
            startDate: colStartDate[i],
            inUse: colInUse[i]
          }
          streams.push(v);
          if (sensor === colSensorID[i] && !v.inUse) {
            hasStream = true;
            sensorHost = v;
          }
        });
      }
    }
    hosts = streams;

    if (hasOtherStreams != (hosts.length > 0))
      setHasOtherStreams(hosts.length > 0);

    if (hasActiveStream != hasStream) {
      setHasActiveStream(hasStream);
    }
  }

  const selectStreamHost = function (s: StreamHost) {
    console.log("Set host: ");
    console.log(s);
    setSelectedHost(s);
  }

  const renderStreamsList = (): JSX.Element[] => {
    const children: JSX.Element[] = [];
    hosts.forEach(s => {
      if (!s.inUse)
        children.push(<Button onClick={() => { openModal(); selectStreamHost(s) }} title="Live" variant="secondary" className="sp-btn-list">
          <i className="fas fa-video" style={{ marginRight: "3px", color: "red" }}></i>{s.name}
          <i className="fas fa-cog" style={{ marginRight: "3px", marginLeft: "10px", color: "red" }}></i>{s.sensorID}
        </Button>);
    });

    return children;
  }

  const getProxyUrl = () => {
    const publicApiName = 'public-api';
    const srv = getDataSourceSrv();
    return srv.get(publicApiName).then(response => {
      const us = response as any;
      proxyURL = `/api/datasources/${us.id}/resources`;
    });
  }

  const toggleMuted = async (_stream: MediaStream) => {
    const audioTracks = _stream.getAudioTracks();
    audioTracks[0].enabled = isMuted;
    setIsMuted(!isMuted);
  }

  const setMuted = async (value: boolean) => {
    setIsMuted(value);
  }

  getStreams();
  loadQrCode();

  return (
    <div>
      <div className="sp-main-btn">
        <div>
          <Button onClick={() => { openModal(); selectStreamHost(sensorHost) }} title="Live" variant="secondary" hidden={!hasActiveStream}>
            <i className="fas fa-video" style={{ color: "red" }}></i>
          </Button>
          <Button title="Offline" variant="secondary" disabled={true} hidden={hasActiveStream}>
            <i className="fas fa-video" style={{ color: "grey" }}></i>
          </Button>
          <Button onClick={() => openModalList()} title="List" variant="secondary" hidden={!hasOtherStreams} >
            <i className="fas fa-list" style={{ color: "red" }}></i>
          </Button>
          <Button title="No streams" variant="secondary" disabled={true} hidden={hasOtherStreams} >
            <i className="fas fa-list" style={{ color: "grey" }}></i>
          </Button>
          <Button onClick={() => showQrCode()} title="QR code" disabled={!hasQR()} variant="secondary">
            <i className="fas fa-qrcode" style={{ color: "white" }}></i>
          </Button>
        </div>
      </div>
      <Modal title="Live stream" isOpen={modalIsOpen} onDismiss={closeModal} className="sp-modal" contentClassName="sp-modal-content" icon="camera">
        <LoadingPlaceholder className="sp-loading" text='Connecting...' hidden={isLive} />
        {renderPlayer()}
      </Modal>
      <Modal title="Select stream" isOpen={modalListIsOpen} onDismiss={closeModal} className="sp-modal" contentClassName="sp-modal-content" icon="camera" >
        {renderStreamsList()}
      </Modal>
      <Modal title="Scan QR code" isOpen={modalQrIsOpen} onDismiss={closeModal} className="sp-modal" contentClassName="sp-modal-content" icon="info-circle" >
        <img src={basse64QR} alt="QR Code" style={{ height: '500px', width: '500px' }} />
      </Modal>
    </div >
  );

};
function addFocusPoint(e: MouseEvent) {
  //const element = (<div className="pulsating-circle"></div>) as HTMLElement;
  const element = document.createElement("div");
  const elId = "focus_" + (new Date).getTime();
  element.setAttribute("id", elId);

  const remoteVideo = document.getElementById('remoteVideoContainer') as HTMLElement;
  remoteVideo.appendChild(element);

  element.style.position = 'fixed';
  var x = e.pageX;
  var y = e.pageY;


  element.style.left = `${x}px`;
  element.style.top = `${y}px`;
  element.classList.add("pulsating-circle");

  setTimeout(function () {
    element.remove();
  }, 5000);
}



